.label2 {
    text-align: left;
    color: black;
    margin: 15px 5px 0px 5px;
    font-size: 22px;
    font-weight: 500;
}

.label-center {
    text-align: center;
    margin: 15px 5px 0px 5px;
}

/* .me dium-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    z-index: 1000;
}

.mediu m-modal-dark {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(33, 24, 54, 0.9);
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    z-index: 1000;
} */

.flex-content {
    display: flex;
    justify-content: center;
}

.flex-full {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login {
    width: 100%;
}

.login input {
    width: 60vw;
}

.builder-input {
    width: 370px;
    height: 37px;
    margin: 5px 5px 0px 5px;
}

.logo2 {
    margin: 2px 4px 4px 5px;
    width: 25px;
    height: 27px;
    z-index: 0;
}

.pointer {
    cursor: pointer;
}

.error {
    color: red;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0;
    text-align: center;
}

.aligned {
    text-align: center;
    align-items: center;
    padding: 0;
}

.front-button {
    border-radius: 5px 0 0 5px;
}

.middle-button {
    border-radius: 0;
}

.end-button {
    border-radius: 0 5px 5px 0;
}

.margin-left-3 {
    margin-left: 3px;
}

.margin-top-20 {
    margin-top: 20px;
}

.hidden4 {
    display: none;
}

.media-flex-center {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 800px) {
    .media-flex {
        display: flex;
    }

    .mediaHidden {
        display: none;
    }
}
