body {
  margin-bottom: 200px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255), rgba(255, 255, 255), #5ad2d4b6);
  /* background-size: 85%, 60%; */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  z-index: -1;
}

.app {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --window-height: calc(100vh - 264px);
  /* --color-body: #fff; */
}

.content {
  min-height: var(--window-height);
}

.footer {
  position: relative;
  /* background-color: #74d1d2; */
  background-color: #63b2d6;
  /* height: 120px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  min-height: 192px;
}

.footerHead {
  margin: 35px auto 0px auto;
  color: white;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.footerLinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
}

.footerLink {
  margin: 10px;
  color: white;
  font-weight: 500;
}

.copyRight {
  margin: 15px;
  color: white;
  font-weight: 400;
  margin: 0px auto 20px auto;
  font-family: 'Times New Roman', Times, serif;
}

.back-to-top {
	height: 65px;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	cursor: pointer;
	opacity: 100%;
	transition: opacity 0.5s;
  z-index: 2;
}

.sectionContainer {
  margin: 0 40px 0 40px;
}

.sectionHeader {
  text-align: start;
  font-size: 22px;
}

.section {
  text-align: start;
}

.lightSwitch {
  height: 65px;
  position: fixed;
  right: 2rem;
  bottom: 7rem;
  cursor: pointer;
  opacity: 30%;
  transition: opacity 0.5s;
  z-index: 900;
}

.lightSwitch:hover {
  height: 65px;
  position: fixed;
  right: 2rem;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
}

.margin-0 {
  margin: 0;
}

.marginTop-10 {
  margin-top: 10px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginTop-0 {
  margin: 0;
}

.marginBottom-30 {
  margin-bottom: 30px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginBottom-0 {
  margin-bottom: 0;
}

.navBarContainer {
  position: sticky;
  height: 72px;
  top: 0;
  background-color: #ffffff;
}

.textCenter {
  text-align: center;
}

.flexFull {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.flexItems {
  display: flex;
  align-items: center;
}

.flexColumnFull {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}

.flexColumnItems {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.flexColumnItems {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.welcome {
  font-size: 60px;
}

.contactBox {
  background-color: #294377;
  max-width: 450px;
  min-height: 746px;
  margin: 20px auto 50px auto;
}

.contactBox2 {
  /* background-color: #74d1d285 ; */
  max-width: fit-content;
  /* min-height: 746px; */
  margin: 0 auto 70px auto;
}

.reach {
  color: white;
  font-size: 33px;
  text-align: left;
  margin: 20px 45px 0 45px ;
}

.inputContainer {
  margin: 20px auto 20px auto;
  width: 350px;
}

.formInput {
  width: 348px;
  height: 37px;
  margin: 0;
  padding: 0;
  border: white 1px solid;
}

.formTextArea {
  width: 348px;
  height: 120px;
  margin: 0;
  padding: 0;
  border: white 1px solid;
}

.label {
  color: white;
  font-size: 22px;
  margin: 0;
  text-align: left;
  font-weight: 500;
}

.blueSubmit {
  background-color: #104d86;
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  border-radius: 7px;
  height: 50px;
  width: 350px;
  border: white 1px solid;
  margin: 20px auto 40px auto;
}

.contactHeader {
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  margin: 20px 0 0 20px;
  font-family: 'Times New Roman', Times, serif;
}

.contactText {
  font-size: 20px;
  text-align: left;
  margin: 10px 0 0 20px;
  padding: 0;
}

.contactText2 {
  font-size: 20px;
  text-align: left;
  margin: 10px 0 0 20px;
}

.contactText3 {
  font-size: 20px;
  text-align: left;
  margin: 0px 0 0 20px;
}

.imageContainer {
  position: absolute;
  z-index: -10;
  width: 100vw;
  height: 300px;
  top: 72px;
  filter: opacity(40%);
  overflow: hidden;
  border-bottom: #74d1d2 solid 5px;
}

.imageContent {
  width: 100vw;
}

.flex {
  display: flex;
}

.articlesContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
}

.articleBoxTop {
  background-color: #8bc1da;
  height: 300px;
  width: auto;
  margin: auto;
  border-radius: 0 0 0 0;
  outline: #294377 solid 5px;
  overflow: hidden;
}

.articleBoxBottom {
  background-color: #294377;
  height: 150px;
  width: auto;
  margin: 0 0 30px 0;
  border-radius: 0 0 0 0;
  outline: #294377 solid 5px;
}

.articleImage {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  /* object-position: center; */
}

.articleImage2 {
  width: 90%;
  height: auto;
  margin: auto;
  /* object-fit: cover; */
  /* object-fit: cover; */
  /* object-position: center; */
}

.articleTitleLink {
  color: white;
  font-weight: 400;
  font-size: larger;
  padding: 0;
  margin: auto 10px auto 10px;
}

.articleTitle {
  text-align: left;
  margin: auto;
}

.iconContainer {
  width: 50px;
}

.articleAD {
  text-align: left;
  margin: auto;
  font-weight: 500;
}

.userIcon {
  height: 50px;
  width: 50px;
  margin: auto;
  border-radius: 50px;
  background-color: #104d86;
}

.userIconOverlay {
  font-size: 30px;
  font-weight: 500;
  margin: auto;
  color: white;
}

.navLink {
  text-decoration: none;
}

.navLink:visited {
  outline: none; /* removes outline on visited links */
  border: none; /* removes border on visited links */
  color: inherit; /* preserves original color */
}

.serviceBoxBottom {
  background-color: #294377;
  height: 170px;
  width: auto;
  margin: 0 0 30px 0;
  border-radius: 0 0 0 0;
  outline: #294377 solid 5px;
  transition: all 0.5s;
  overflow: hidden;
}

.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: all 0.3s ease; /* Fades out instead of disappearing abruptly */
}

.serviceTitle {
  color: white;
  margin: 12px;
  font-size: x-large;
  font-weight: 500;
}

.serviceText {
  color: white;
  font-weight: 400;
  font-size: larger;
  padding: 0;
  margin: 0px 12px 0px 12px;
}

.arrow {
  height: 10px;
  width: 30px;
  margin: 0;
  padding: 0;
}

.arrowContainer {
  position: relative;
  height: 10px;
  /* bottom: 95%; */
}

.hidden2 {
  display: none;
}

.mediaHidden {
  display: block;
}

.bioPic {
  max-width: 90%;
  margin: 10px;
  padding: 0;
}

.bioCredText {
  font-size: large;
  font-weight: 500;
  margin: 5px;
}

.logo {
  margin: 30px auto;
  width: 80vw;
  max-width: 500px;
}

.mainContactBox {
  /* background-color: #74d1d285 ; */
  max-width: fit-content;
  /* min-height: 746px; */
  margin: 0 auto;
}

.mainContactText {
  font-size: 20px;
  text-align: left;
  margin: 15px 25px 0 25px;
}

.mainServicesContainer {
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.mainServiceBox {
  display: flex;
  background-color: #294377;
  justify-items: center;
  justify-content: center;
  min-height: 60px;
  width: fit-content;
  transform: skew(-20deg);
  margin: 10px 28px;
}

.mainServiceBoxContent {
  transform: skew(20deg); /* Opposite skew to cancel out */
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin: 10px;
}

.mainServiceText {
  font-size: 20px;
  text-align: center;
  margin: 5px 25px 5px 25px;
}

.pointer {
  cursor: pointer;
}

.buttonFill {
  height: 50px;
  width: 350px;
}

.messageRow {
  display: block;
  border: black 1px solid;
}

.messageCellFront {
  border:none;
}

.messageCellMid {
  border:none;
}

.messageCellEnd {
  border:none;
}

.wrapText {
  word-wrap: normal;
}

@media screen and (min-width: 800px) {
  .flexColumnFullInner {
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
  }

  .articleBoxTop {
    /* background-color: #294377; */
    height: 300px;
    width: 450px;
    margin: auto;
    border-radius: 9px 9px 0 0;
    outline: #294377 solid 5px;
    overflow: hidden;
  }

  .articleBoxBottom {
    background-color: #294377;
    height: 150px;
    width: 450px;
    margin: auto;
      border-radius: 0 0 9px 9px;
    outline: #294377 solid 5px;
  }

  .articlesContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 450px));
    gap: 20px;
    grid-auto-flow: dense;
  }

  .serviceBoxBottom {
    background-color: #294377;
    height: 150px;
    width: 450px;
    margin: auto;
    border-radius: 0 0 9px 9px;
    outline: #294377 solid 5px;
    transition: all 0.3s;
  }

  .mediaDisplay {
    display: block;
  }

  .mediaFlex {
    display: flex;
  }

  .mediaFlexFull {
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  .mediaHidden {
    display: none;
  }

  .mediaSplit {
    width: 50vw;
  }

  .mainContactBox {
    display: flex;
    max-width: fit-content;
    /* min-height: 746px; */
    margin: 0 auto;
  }

  .carouselContainer {
    overflow-x: hidden;
    display: flex;
    justify-items: center;
    height: 480px;
  }

  .carouselBoxTop {
    /* background-color: #294377; */
    height: 300px;
    width: 450px;
    margin: 10px auto 0px auto;
    padding: 0;
    border-radius: 0 0 0 0;
    outline: #294377 solid 5px;
    overflow: hidden;
  }

  .carouselBoxBottom {
    background-color: #294377;
    height: 150px;
    width: 450px;
    margin: auto 8px;
    padding: 0;
    border-radius: 0 0 0 0;
    outline: #294377 solid 5px;
  }

  .carouselLink {
    text-decoration: none;
    padding: 0;
    margin: 0;
  }

  .carouselLink:visited {
    outline: none; /* removes outline on visited links */
    border: none; /* removes border on visited links */
    color: inherit; /* preserves original color */
  }

  .mainServiceBox {
    margin: 10px;
  }

  .messageTable {
    border: black 2px solid;
  }

  .messageRow {
    display: flex;
  }

  .messageCellFront {
    border: black 1px solid;
  }

  .messageCellMid {
    border: black 1px solid;
  }

  .messageCellEnd {
    border: black 1px solid;
  }
}
